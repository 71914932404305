import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    wrapper,
    pricing,
    faq,
    faqButton,
    description,
    slider,
    opinions,
} from './cennik.module.scss';
import getNodes from '../utills/get-nodes';

import SEO from '../components/seo';
import Main from '../layouts/main';
import Pricing from '../components/organisms/pricing';
import Faq from '../components/organisms/faq';
import Button from '../components/atoms/button';
import ProductDescription from '../components/organisms/product-description';
import MetamorphosisSlider from '../components/organisms/metamorphosis-slider';
import VideoOpinions from '../components/organisms/video-opinions';

const PricingPage = () => {
    const { product, allMetamorphosis } = useStaticQuery(query);
    const pricingRef = useRef(null);

    const metamorphosis = getNodes(allMetamorphosis);

    const handleScrollToPricing = () => {
        if (!pricingRef.current) return;
        pricingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
            <SEO title="Cennik" />
            <Main className={wrapper} hasJoinBox={false}>
                <section className={pricing} ref={pricingRef}>
                    <Pricing TitleTag="h1" useStructuredData={true} />
                </section>
                <section className={faq}>
                    <Faq />
                    <Button color="yellow" className={faqButton} onClick={handleScrollToPricing}>
                        Zacznij teraz ze zniżką!
                    </Button>
                </section>
                <section className={description}>
                    <ProductDescription description={product.description} />
                </section>
                {metamorphosis.length > 0 && (
                    <section className={slider}>
                        <MetamorphosisSlider slides={metamorphosis} />
                    </section>
                )}
                <VideoOpinions
                    className={opinions}
                    title="Sprawdź, co mówią o nas nasi podopieczni"
                    bigTitle={true}
                />
            </Main>
        </>
    );
};

const query = graphql`
    query {
        product(productId: { eq: 1 }) {
            description
        }
        allMetamorphosis(sort: { fields: createdAt, order: DESC }, limit: 5) {
            edges {
                node {
                    author
                    content
                    imageUrl
                }
            }
        }
    }
`;

export default PricingPage;
